import React from 'react'
import './App.css';
import {
	AppBar,
	Button,
	Dialog,
	DialogTitle,
	IconButton,
	Link,
	Paper,
	Toolbar
} from '@material-ui/core'
import Logo from './Images/ACI Logo.png'
import Command from './Images/Command and Control.PNG'
import Educate from './Images/Educate and Inform.PNG'
import Blended from './Images/Blended Sessions.PNG'

import '@fontsource/league-spartan/700.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MenuIcon from '@material-ui/icons/AccountCircle';

function OutboundSessionComplete() {
	const [dialogOpen, setDialogOpen] = React.useState(false)

	const openDialog = () => {
		setDialogOpen(true)
	}

	const closeDialog = () => {
		setDialogOpen(false)
	}

	return (
		<div className='App'>
			<Dialog maxWidth='sm' className='modal' open={dialogOpen}>
				<DialogTitle className='modal-title'>
					<span>
						Download Course Materials
					</span>
				</DialogTitle>
				<div className='modal-image-container'>
					<span className='modal-subtitle'>Available Materials</span>
					<div className='modal-images'>
						<span className='modal-images--command'>
							<a
								href='https://storage.googleapis.com/lms-training-materials/modern-retailing-outbound-comman-and-control.pdf'
								target='_blank' rel='noopener noreferrer'
							>
								<img
									className='modal--image'
									alt='Command and Control'
									src={Command}
								></img>
							</a>
						</span>
						<span>
							<a href='https://storage.googleapis.com/lms-training-materials/modern-retailing-outbound-educate-and-inform.pdf'
								target='_blank' rel='noopener noreferrer'
							>
								<img
									className='modal--image'
									alt='Educate and Inform'
									src={Educate}
								></img>
							</a>
						</span>
						<span className='modal-images--blended'>
							<a href='https://storage.googleapis.com/lms-training-materials/modern-retailing-outbound-blended.pdf'
								target='_blank' rel='noopener noreferrer'
							>
								<img
									className='modal--image'
									alt='Blended Sessions'
									src={Blended}
								></img>
							</a>
						</span>
					</div>
					<Button
						onClick={closeDialog}
						className='modal-close-btn'
					>Close this window</Button>
				</div>
			</Dialog>
			<Paper className='App-container' elevation={3}>
				<AppBar className='App-header'>
					<Toolbar className='App-header--toolbar'>
						<img
							className='App-header--logo'
							alt='Applied Concepts Logo'
							src={Logo}
						></img>
						<div className='App-header--links'>
							<Link
								href='https://perform.appliedconcepts.com/learn/dashboard'
								target='_blank'
								rel='noreferrer'
								className='my-dashboard'
							>MY DASHBOARD</Link>
							<Link
								href='https://perform.appliedconcepts.com/support'
								target='_blank'
								rel='noreferrer'
								className='support'
							>SUPPORT</Link>
						</div>
						<Link
							className='mobile-menu-icon'
							href='https://perform.appliedconcepts.com/learn/dashboard'
							target='_blank'
							rel='noreferrer'
						>
							<IconButton
								className='mobile-menu-icon'
								color='inherit'
								aria-label='menu'
							>
								<MenuIcon />
							</IconButton>
						</Link>
					</Toolbar>
				</AppBar>
				<div className='Hero-container'>
					<div className='Hero-opacity'>
						<div className='Hero-image'>
						</div>
						<span className='Hero-text'>Session Complete</span>
					</div>
				</div>
				<div className='Download-container'>
					<div className='Download-container--thanks'>
						<span>Thank You</span>
					</div>
					<div className='Download-container--msg'>
						<span>
							You have completed your session for the week. You will receive an email over the upcoming weekend with instructions on how to complete next week's session.
						</span>
					</div>
					<div className='Download-container--btn-container'>
						<Button
							className='Download-container--btn'
							onClick={openDialog}
						>Download Course Materials</Button>
					</div>
				</div>
				<div className='Video-container'>
					<span className='Video-container--msg'>More Free Content for you</span>
					<div className='Video-container--videos'>
						<iframe
							title='Best Practices For Inbound Calls'
							className='Video-left'
							width='21%'
							height='150'
							src='https://www.youtube.com/embed/Gcb96NWhvSQ'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
						<iframe
							title='Two Selling Methods'
							className='Video-right'
							width='21%'
							height='150'
							src='https://www.youtube.com/embed/Bh-TPZohxig'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</div>
					<Carousel
						className='Video-carousel'
						autoplay={false}
						swipeable={true}
						emulateTouch={true}
						showStatus={false}
						interval={6000000}
						showThumbs={false}
					>
						<iframe
							title='Best Practices For Inbound Calls'
							className='Video-left'
							width='21%'
							height='150'
							src='https://www.youtube.com/embed/Gcb96NWhvSQ'
							frameBorder='20'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
						<iframe
							title='Two Selling Methods'
							className='Video-right'
							width='21%'
							height='150'
							src='https://www.youtube.com/embed/Bh-TPZohxig'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						></iframe>
					</Carousel>
				</div>
				<div className='footer'>
					<div className='footer-left'>
						© 2021 Applied Concepts
					</div>
					<div className='footer-right'>
						<strong>Need Help?</strong>
						<a
							className='footer-right--support-link'
							href='https://perform.appliedconcepts.com/support'
							target='_blank'
							rel='noreferrer'
						>Contact Support</a>
					</div>
				</div>
			</Paper>
		</div>
	);
}

export default OutboundSessionComplete;