import React from 'react';
import MonthlySessionComplete from './MonthlySessionComplete'
import WeeklySessionComplete from './WeeklySessionComplete'
import OutboundSessionComplete from './OutboundSessionComplete';
import InboundOutboundSessionComplete from './InboundOutboundSessionComplete';
import InboundSessionComplete from './InboundSessionComplete';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';


function App() {

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={WeeklySessionComplete} />
        <Route exact path='/electives' component={MonthlySessionComplete} />
        <Route exact path='/inbound' component={InboundSessionComplete} />
        <Route exact path='/outbound' component={OutboundSessionComplete} />
        <Route exact path='/inbound-outbound' component={InboundOutboundSessionComplete} />
      </Switch>
    </Router>
  );
}

export default App;
